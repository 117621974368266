import useUser from 'hooks/useUser';
import {dictionaryGlobal} from 'apis/dictionary/global';

export default function useMultilang() {
  const {selectedLanguage, translations, setSelectedLanguage} = useUser();

  const getT = (key) => {
    let token =
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1dWlkIjoiMTFFRjE3NjZDQUIwOUQ1MDlFMzgzM0E4NDg0NDMwQ0EiLCJzaWQiOiJ3dEFLSmozRnNzVVF1UTI4Z2M4VVEiLCJpYXQiOjE3MTg3ODM4ODUsImV4cCI6MTcxODg3MDI4NX0.9ZiLQUXsGABNpqzVQkte_wRH73hqsj2Js7JEH7uSzig';
    if (key != null && process.env.NODE_ENV === 'development' && translations?.[key] == null) {
      dictionaryGlobal.addTranslation(token, key).then((res) => {
        if (res.success) {
          console.log(res);
        } else {
          console.log(res);
        }
      });
    }
    return translations?.[key] && translations?.[key] !== '' ? translations?.[key] : key;
  };

  return {
    language: selectedLanguage,
    translations,
    getT,
    setLanguage: setSelectedLanguage,
  };
}
