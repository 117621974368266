export default function ServerOffline() {
  return (
    <div
      className=""
      style={{
        width: "100%",
        height: "100%",
        background: "#FFFFFF",
        position: "relative",
      }}
    >
      <div
        className=""
        style={{
          position: "fixed",
          top: "80px",
          width: "100%",
          color: "#000000",
          textAlign: "center",
          fontSize: "32px",
        }}
      >
        <p>Az oldal karbantartás alatt áll.</p>
      </div>
      <img
        src="/underconst.jpg"
        width={"100%"}
        height={"100%"}
        alt="unc"
        style={{ objectFit: "contain" }}
      />
    </div>
  );
}
