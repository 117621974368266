import axios from 'axios';
import config from 'config';

export const ntakWorkDays = {
  getWorkDays: async (token, providerID, filter = {}) => {
    if (token == null) return {success: false, data: null, errorCode: 450};
    try {
      const axiosconfig = {
        params: {
          providerID,
          filter: JSON.stringify(filter),
        },
      };
      const response = await axios
        .create({
          timeout: 15000,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        })
        .get(`${config().apiBaseUrl}/api/ntak/day`, axiosconfig);
      return response.data;
    } catch (error) {
      console.log(error);
      return {success: false, data: null};
    }
  },
  setWorkDay: async (token, providerID, day) => {
    if (token == null) return {success: false, data: null, errorCode: 450};
    try {
      const response = await axios
        .create({
          timeout: 15000,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        })
        .post(`${config().apiBaseUrl}/api/ntak/day`, {
          providerID,
          day,
        });
      return response.data;
    } catch (error) {
      console.log(error);
      return {success: false, data: null};
    }
  },
  submitWorkDay: async (token, providerID, dayID) => {
    if (token == null) return {success: false, data: null, errorCode: 450};
    try {
      const response = await axios
        .create({
          timeout: 15000,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        })
        .post(`${config().apiBaseUrl}/api/ntak/day/submit`, {
          providerID,
          dayID,
        });
      return response.data;
    } catch (error) {
      console.log(error);
      return {success: false, data: null};
    }
  },
};
