import "style.scss";
import Router from "utils/Router";
import Navigation from "views/Navigation";
import { BrowserRouter } from "react-router-dom";
import { UserProvider } from "./contexts/UserContext";
import { ProviderProvider } from "contexts/ProviderContext";
import { OrdersProvider } from "contexts/OrdersContext";
import { WorkDaysProvider } from "contexts/WorkDaysContext";
import { NtakUsersProvider } from "contexts/NtakUsersContext";
import { NtakTokensProvider } from "contexts/NtakTokensContext";

function App() {
  return (
    <UserProvider>
      <ProviderProvider>
        <NtakUsersProvider>
          <WorkDaysProvider>
            <OrdersProvider>
              <NtakTokensProvider>
                <BrowserRouter>
                  <Navigation>
                    <Router />
                  </Navigation>
                </BrowserRouter>
              </NtakTokensProvider>
            </OrdersProvider>
          </WorkDaysProvider>
        </NtakUsersProvider>
      </ProviderProvider>
    </UserProvider>
  );
}

export default App;
