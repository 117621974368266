import axios from "axios";

export const serviceState = {
  getState: async () => {
    try {
      console.log(window.location.hostname);
      const hostname =
        window.location.hostname === "localhost"
          ? "ntak.fruitsys.hu"
          : window.location.hostname;
      const response = await axios
        .create({
          timeout: 15000,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .get(
          `https://auth.okeoke.io/api/v1/services/state/ntak.service/${hostname}`
        );
      return response.data;
    } catch (error) {
      console.log(error);
      return { success: false, data: null };
    }
  },
};
