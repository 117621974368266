import useSvg from "hooks/useSvg";
import useUser from "hooks/useUser";
import pack from "../../../package.json";
import useMultilang from "hooks/useMultilang";
import Login from "views/Login";
import UserLanguages from "./UserLanguages";
import LightModeSwitch from "./LightModeSwitch";
import MenuElement from "./MenuElement";
import { useNavigate, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import UserProfile from "./UserProfile";
import SSOLogin from "views/SSOLogin";
import ServerOffline from "views/ServerOffline";
import { useEffect, useState } from "react";
import { serviceState } from "apis/serviceState";

function Navigation(props) {
  const { getT } = useMultilang();
  const { settings, toggleSidebar, loggedIn, logout, newVersionAvailable } =
    useUser();
  const { getSvg } = useSvg();
  const navigate = useNavigate();
  const location = useLocation();

  const [serviceData, setServiceData] = useState(null);

  useEffect(() => {
    serviceState.getState().then((res) => {
      if (res.success) {
        setServiceData(res.data);
      }
    });
  }, []);

  if (serviceData != null && serviceData?.serviceState !== "online")
    return <ServerOffline />;

  if (!loggedIn && location?.pathname.includes("sso")) return <SSOLogin />;

  if (!loggedIn) return <Login />;

  return (
    <div className={`navigation-root`}>
      <div
        className={`hidden appear side-bar ${
          settings?.sidebarClosed ? "closed" : "open"
        }`}
      >
        <div className="side-bar-toggler" onClick={() => toggleSidebar()}>
          {getSvg("menu")}
        </div>
        <div className="side-bar-logo" onClick={() => navigate("/")}>
          NTAK Portal
        </div>
        <UserProfile />
        <div className="side-bar-menu">
          <MenuElement viewName="stats" />
          <MenuElement viewName="providers" />
          <MenuElement viewName="orders" />
          <MenuElement viewName="days" />
          <MenuElement viewName="users" />
          <MenuElement viewName="tokens" />
          <MenuElement viewName="faq" url={"https://info.ntakportal.hu/"} />
        </div>
        <div className="side-bottom-container">
          <UserLanguages />
          <LightModeSwitch />
          <div className="logout-container">
            <div className="menu-element" onClick={() => logout()}>
              {getSvg("exit")}
              <div className="menu-text">{getT("ntak.login.logout")}</div>
            </div>
          </div>
          <div className="version">{`v${pack.version}`}</div>
        </div>
      </div>
      <div className="main-content">
        <div
          className={`update-warning ${
            newVersionAvailable ? "open" : "closed"
          }`}
          onClick={() => window.location.reload(true)}
        >
          {getT("ntak.info.newVersionAvailable")}
        </div>
        <div className="top-bar">
          <div className="breadcrumbs">
            {getT(
              `ntak.topBar.breadcrumbs.${
                (location?.pathname || []).split("/")[1]
              }`
            )}
          </div>
        </div>
        <div className="content-container">
          <ToastContainer
            position="top-center"
            autoClose={300}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover
            toastStyle={
              settings?.lightMode
                ? {}
                : { backgroundColor: "#1f2023", color: "#eceff1" }
            }
          />
          {props.children}
        </div>
      </div>
    </div>
  );
}
export default Navigation;
